import React, { useState } from 'react'
import Popup from '../components/Popup'
import { links } from '../constant'

export default function Earn() {
    const [open, setOpen] = useState(false)
    return (
        <section className="earn p-2 py-5">
            <div className="container">
                <div className="row justify-content-between" >
                    <div style={{ color: '#fff' }} className="col-12 col-md-6">
                        <div className=" fw-bold h2">
                            Are you a Drone Pilot ?
                        </div>
                        <div className="h4">
                            #Earn Money
                        </div>
                        <br />
                        <div className="h6">
                            If you are a drone pilot and own a drone, you can make money with Soaron's Pilot App.
                        </div>
                    </div>

                    <div className="col-12 col-md-4 pt-2">
                        <div style={{ color: '#fff' }} className="h5 my-3 text-md-center">
                            Download the App
                        </div>
                        <div className="d-flex justify-content-md-center">
                            <a target={'_blank'} rel='noreferrer' className='earn-btn py-2 py-md-3 px-4 px-md-5 me-4' href={links.flytor_android}>ANDROID</a>
                            <a target={'_blank'} rel='noreferrer' className='earn-btn py-2 py-md-3 px-4 px-md-5' href={links.flytor_ios}>IPHONE</a>
                            {/* <div role='button' className='earn-btn py-2 py-md-3 px-4 px-md-5' onClick={() => setOpen(true)} >IPHONE</div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Popup content="Delay in Flytor iOS App" para="We couldn't able resolve iOS deployment issues and we apologies for the delay. Our product team working on this and we are going to deploy and make it ready for you all, as early as possible." show={open} onHide={() => setOpen(false)} />
        </section>
    )
}
