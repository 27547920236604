export const links = {
    fly_android:
        "https://play.google.com/store/apps/details?id=com.soaron.fly2",
    fly_ios: "https://apps.apple.com/us/app/fly-soaron/id6449644308",
    flytor_android:
        "https://play.google.com/store/apps/details?id=com.soaron.flytor",
    flytor_ios: "https://apps.apple.com/app/flytor/id6444050069",
    twitter: "https://twitter.com/SoaronOfficial",
    instagram: "https://www.instagram.com/soaronofficial",
    linkedin: "https://www.linkedin.com/company/soaron",
    youtube: "https://www.youtube.com/channel/UCVYcJj6qHAIOa9zBAnLcnbA",
};
